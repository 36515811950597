import { client } from 'src/api'

export const removeFriend = async (friendId) => await client.delete(`/api/v3/friends/${friendId}`)

export const getFriends = async (userId, page, perPage, cutoffTime, sortAlphabetically = false) => {
  const params = {
    page,
    per_page: perPage,
    cutoff_time: cutoffTime,
    sort_alphabetically: sortAlphabetically,
  }

  return userId
    ? await client.get(`/api/v3/friends?for_member=${userId}`, { params })
    : await client.get('/api/v3/friends', { params })
}
