import { Service } from 'src/services/base/Service'

type CarouselServiceRequest = never

export class CarouselService extends Service {
  public static methods = {} as const

  public static getSWRKey(request: CarouselServiceRequest): string {
    const { method } = request
    switch (method) {
      default:
        throw new Error('Method not implemented')
    }
  }
}
