import { Service } from 'src/services/base/Service'
import type { FriendsActivity } from 'src/types/friends'
import type { Member } from 'src/types/Member'
import type { MySchedule } from 'src/types/Schedule'
import type { WeeklyClassActivity } from 'src/types/User'

interface GetMemberRequest {
  method: typeof MemberService.methods.GetMember
  params: { username: string; userId?: string }
}

interface GetFriendsActivityRequest {
  method: typeof MemberService.methods.GetFriendsActivity
  params: { userId: string }
}

interface GetWeeklyClassActivityRequest {
  method: typeof MemberService.methods.GetWeeklyClassActivity
  params: { userId: string }
}

interface GetMemberScheduleRequest {
  method: typeof MemberService.methods.GetMemberSchedule
  params: { start_date: string }
}

type MemberServiceRequest =
  | GetMemberRequest
  | GetFriendsActivityRequest
  | GetWeeklyClassActivityRequest
  | GetMemberScheduleRequest

export class MemberService extends Service {
  public static methods = {
    GetMember: 'getMember',
    GetFriendsActivity: 'getFriendsActivity',
    GetWeeklyClassActivity: 'getWeeklyClassActivity',
    GetMemberSchedule: 'getMemberSchedule',
  } as const

  public static getSWRKey(request: MemberServiceRequest) {
    const { method, params } = request
    switch (method) {
      case MemberService.methods.GetMember:
        return this.buildRequestUrl('/api/v4/member', params)
      case MemberService.methods.GetFriendsActivity:
        return this.buildRequestUrl(`/api/v4/members/${params.userId}/friends_activity`)
      case MemberService.methods.GetWeeklyClassActivity:
        return this.buildRequestUrl(`/api/v4/members/${params.userId}/weekly_class_activity`)
      case MemberService.methods.GetMemberSchedule:
        return this.buildRequestUrl('/api/v4/members/my_schedule', params)
      default:
        throw new Error('Method not implemented')
    }
  }

  public static async getMember(params: GetMemberRequest['params']) {
    return await this.get<Member>(
      this.getSWRKey({ method: MemberService.methods.GetMember, params }),
    )
  }

  public static async getFriendsActivity(params: GetFriendsActivityRequest['params']) {
    return await this.get<FriendsActivity>(
      this.getSWRKey({ method: MemberService.methods.GetFriendsActivity, params }),
    )
  }

  public static async getWeeklyClassActivity(params: GetWeeklyClassActivityRequest['params']) {
    return await this.get<WeeklyClassActivity>(
      this.getSWRKey({ method: MemberService.methods.GetWeeklyClassActivity, params }),
    )
  }

  public static async getMemberSchedule(params: GetMemberScheduleRequest['params']) {
    return await this.get<MySchedule>(
      this.getSWRKey({ method: MemberService.methods.GetMemberSchedule, params }),
    )
  }
}
