import { useEffect, type DetailedHTMLProps, type HTMLAttributes, type ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'
import { api } from 'src/api'
import { defaults } from 'src/constants'

const { TRACK_LOGGED_IN_ONLY, THRESHOLD } = defaults.impressionTracker

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  action: string
  tracking?: Record<string, unknown>
  trackOnlyLoggedIn?: boolean
  threshold?: number
  children?: ReactNode
}

export const ImpressionTracker = ({
  trackOnlyLoggedIn = TRACK_LOGGED_IN_ONLY,
  children,
  ...props
}: Props) => {
  const shouldTrack = !trackOnlyLoggedIn || (trackOnlyLoggedIn && api.isLoggedIn)

  return shouldTrack ? <TrackedElement {...props}>{children}</TrackedElement> : <>{children}</>
}

const TrackedElement = ({
  action,
  children,
  className,
  tracking,
  threshold = THRESHOLD,
}: Props) => {
  const [inViewRef, isInView] = useInView({ triggerOnce: true, threshold })

  useEffect(() => {
    if (isInView) obe.analytics.track(action, tracking)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView])

  return (
    <Styles.Container ref={inViewRef} className={className}>
      {children}
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    height: 100%;
  `,
}
