import { breakpoint, mixin } from 'src/styles'
import moment from 'moment'
import { useInterval } from 'src/hooks/useInterval'

export const PageHeader = React.memo(
  ({ title, className, uppercase = false, showTime = false, showBorder = true, children }) => (
    <Styles.Container className={className} showBorder={showBorder}>
      {children ? (
        <div>
          <Styles.Title uppercase={uppercase} showBorder={showBorder}>
            {title}
          </Styles.Title>
          {children}
        </div>
      ) : (
        <Styles.Title uppercase={uppercase} showBorder={showBorder}>
          {title}
        </Styles.Title>
      )}
      {showTime && <Clock />}
    </Styles.Container>
  ),
)

const Clock = React.memo(() => {
  const [clockTime, setClockTime] = React.useState(moment().format('h:mm a'))

  useInterval(() => {
    setClockTime(moment().format('h:mm a'))
  }, 1000)

  return <Styles.Time>{clockTime}</Styles.Time>
})

const Styles = {
  Container: styled.div`
    ${mixin.flexCenterVertical}
    justify-content: space-between;
    padding: 0 1.5rem;
    margin: 1.5rem 0;

    ${({ showBorder }) =>
      showBorder &&
      css`
        border-bottom: 0.5px solid #d0d0d0;
        padding: 0 1.5rem 1.5rem 1.5rem;
      `}

    ${breakpoint.mediumAndDown} {
      width: 100%;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      padding: 0;
      margin: 1.1rem 0;
      border-bottom: none;
    }
  `,
  Title: styled.h2`
    font-size: 32px;
    font-weight: 200;
    line-height: 32px;
    margin-bottom: 0;

    ${({ uppercase }) =>
      uppercase &&
      css`
        text-transform: uppercase;
      `}

    ${breakpoint.mediumAndDown} {
      font-size: 24px;
      line-height: 24px;
      width: 100%;
      padding: 0 1.1rem;

      ${({ showBorder }) =>
        showBorder &&
        css`
          border-bottom: 0.5px solid #d0d0d0;
          padding: 0 1.1rem 1.1rem 1.1rem;
        `}
    }
  `,
  Time: styled.div`
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.5px;

    ${breakpoint.mediumAndDown} {
      font-size: 14px;
      line-height: 16px;
      width: 100%;
      padding: 1.1rem;
      border-bottom: 0.5px solid #d0d0d0;
    }
  `,
}
