import { client } from 'src/api'

export const acceptFriendRequest = async (friendRequestId) =>
  await client.patch(`/api/v3/friend_requests/${friendRequestId}?invitation_action=accept`)

export const ignoreFriendRequest = async (friendRequestId) =>
  await client.patch(`/api/v3/friend_requests/${friendRequestId}?invitation_action=ignore`)

export const cancelFriendRequest = async (friendRequestId) =>
  await client.delete(`/api/v3/friend_requests/${friendRequestId}`)

export const sendFriendRequest = async (userId, invitedFromLocation) =>
  await client.post('/api/v3/friend_requests', {
    friendRequest: {
      toUserId: userId,
    },
    invitedFromLocation,
  })

export const getFriendRequests = async (requestType, page, perPage, cutoffTime) =>
  await client.get(`/api/v3/friend_requests?type=${requestType}`, {
    params: {
      page,
      per_page: perPage,
      cutoff_time: cutoffTime,
    },
  })

export const bulkInviteFriends = async (emailsToInvite) =>
  await client.post('/api/v3/friend_requests/bulk_invite_friends', {
    emailsToInvite,
  })
