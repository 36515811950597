import { Service } from 'src/services/base/Service'
import type { ObeContentType } from 'src/types/ObeContent'

interface GetQuickAccessContentRequest {
  method: typeof ContentService.methods.GetQuickAccessContent
}

type ContentServiceRequest = GetQuickAccessContentRequest

export class ContentService extends Service {
  public static readonly methods = {
    GetQuickAccessContent: 'getQuickAccessContent',
  } as const

  public static getSWRKey(request: ContentServiceRequest): string {
    const { method } = request
    switch (method) {
      case ContentService.methods.GetQuickAccessContent:
        return this.buildRequestUrl('/api/v4/content/by_type', { type: 'web_quick_access' })
      default:
        throw new Error('Method not implemented')
    }
  }

  public static async getQuickAccessContent() {
    return await this.get<ObeContentType[]>(
      this.getSWRKey({ method: ContentService.methods.GetQuickAccessContent }),
    )
  }
}
