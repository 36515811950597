import { breakpoint, mixin } from 'src/styles'
import { Icon } from 'src/components/Icon'
import { Link } from 'src/components/Link'
import { useRouter } from 'src/hooks/useRouter'
import { useMemberContext } from 'src/providers/MemberProvider'

export const NavBar = () => {
  const router = useRouter()
  const { canUseSocialFeatures, currentMember, currentUser, isSelf } = useMemberContext()
  const { newFriendRequests } = currentUser?.membershipActivity || {}
  const { numCollectionUpdates } = currentUser || {}
  const { username } = currentMember?.profile || {}

  const pathname = router.asPath

  const linkProps = (to) => ({
    to,
    isActive: pathname === to || (to.includes('collections') && pathname.includes('collections')),
    tracking: { context: 'profile_navbar' },
  })

  const links = [
    {
      to: `/${username || 'profile'}`,
      label: 'Overview',
      shouldRender: true,
    },
    {
      to: `/${username || 'profile'}/collections`,
      icon: linkProps(`/${username || '/profile'}/collections`).isActive ? (
        <Styles.FilledBookmark />
      ) : (
        <Styles.Bookmark />
      ),
      label: 'Collections',
      shouldRender: true,
    },
    {
      to: `/${username || 'profile'}/stats`,
      label: 'Stats',
      shouldRender: isSelf,
    },
    {
      to: `/${username || 'profile'}/friends`,
      label: 'Friends',
      shouldRender: !isSelf || canUseSocialFeatures,
    },
    {
      to: `/${username || 'profile'}/class-history`,
      label: 'Class History',
      shouldRender: isSelf || currentMember?.membershipActivity?.classesCompleted > 0,
    },
  ]

  const renderCountIcon = (label) => {
    switch (label) {
      case 'Friends':
        return newFriendRequests > 0
      case 'Collections':
        return numCollectionUpdates > 0
      default:
        return false
    }
  }

  const getUpdateCount = (label) => {
    switch (label) {
      case 'Friends':
        return newFriendRequests
      case 'Collections':
        return numCollectionUpdates
      default:
        return 0
    }
  }

  return (
    <Styles.Container>
      <Styles.NavBar>
        {links.map(({ to, icon, label, shouldRender }, key) =>
          shouldRender ? (
            <Styles.LinkContainer key={key}>
              <Styles.Link {...linkProps(to)}>
                {label} {!!icon && icon}
                {label !== 'Collections' && isSelf && renderCountIcon(label) && (
                  <Styles.NotificationBadge label={label}>
                    {getUpdateCount(label)}
                  </Styles.NotificationBadge>
                )}
              </Styles.Link>
            </Styles.LinkContainer>
          ) : null,
        )}
      </Styles.NavBar>
    </Styles.Container>
  )
}

const Bookmark = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 9.5'>
    <path
      d='M0 0v9a.56.56 0 0 0 .37.51.58.58 0 0 0 .51-.11l2.55-1.21a.09.09 0 0 1 .11 0l2.58 1.23a.73.73 0 0 0 .59 0A.56.56 0 0
      0 7 9V0zm6.13 8.29c0 .22-.24.14-.25.13L3.53 7.23a.08.08 0 0 0-.08 0L1.1 8.43c-.18.05-.23-.07-.23-.16V.8h5.26z'
    />
  </svg>
)

const Styles = {
  Container: styled.div`
    height: 60px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    background-color: white;
    position: sticky;
    top: 80px;
    z-index: 11;

    ${breakpoint.smallAndDown} {
      top: 62px;
      white-space: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      ::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  `,
  NavBar: styled.nav`
    height: 100%;
    margin: 0 auto;

    ${breakpoint.smallAndDown} {
      ${mixin.spaceChildrenHorizontal('2.5rem')}
      ${mixin.flexCenterVertical}
      width: fit-content;
      padding: 0 16px;
    }

    ${breakpoint.mediumAndUp} {
      ${mixin.spaceChildrenHorizontal('4.5rem')}
      ${mixin.flexCenterBoth}
      width: 650px;
    }
  `,
  LinkContainer: styled.div`
    ${breakpoint.mediumAndUp} {
      text-align: center;
    }
  `,
  Link: styled(Link)`
    position: relative;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.015em;
    text-transform: uppercase;

    :hover {
      color: var(--persianBlue);
      text-decoration: none;
    }

    &:hover {
      path {
        fill: blue;
      }
    }

    ${({ isActive }) =>
      isActive &&
      css`
        color: var(--persianBlue);
        font-weight: 500;
      `}

    ${breakpoint.mediumAndDown} {
      font-size: 16px;
      line-height: 16px;
    }
  `,
  NotificationBadge: styled.div`
    ${mixin.flexCenterBoth}
    position: absolute;
    top: -14px;
    left: 52px;
    background-color: var(--yellow);
    min-width: 20px;
    width: fit-content;
    height: 20px;
    border-radius: 50%;
    font-size: 8px;
    font-weight: 500;
    color: var(--nearBlack);
    line-height: 1;
    padding: 5px;

    ${({ label }) =>
      label === 'Collections' &&
      css`
        left: 100px;
      `}

    @media (max-width: 780px) {
      left: ${(props) => (props.label === 'Collections' ? '120px' : '60px')};
    }

    @media (min-width: 780px) and (max-width: 1020px) {
      left: ${(props) => (props.label === 'Collections' ? '105px' : '63px')};
    }
  `,
  Bookmark: styled(Bookmark)`
    display: inline;
    height: 13px;
    width: 10px;
    margin-bottom: 4px;

    ${breakpoint.largeAndUp} {
      height: 10px;
      margin-bottom: 2px;
    }
  `,
  FilledBookmark: styled(Icon.FilledBookmark)`
    display: inline;
    height: 13px;
    width: 10px;
    margin-bottom: 4px;

    ${breakpoint.largeAndUp} {
      margin-bottom: 2px;
      height: 10px;
      width: 10px;
    }
  `,
}
