export const Location = ({ className }) => (
  <svg width='12' height='14' viewBox='0 0 12 14' fill='none' className={className}>
    <path
      d='M10.8182 5.90909C10.8182 9.72727 5.90909 13 5.90909 13C5.90909 13 1 9.72727 1 5.90909C1 4.60712 1.51721 3.35847 2.43784 2.43784C3.35847 1.51721 4.60712 1 5.90909 1C7.21106 1 8.45971 1.51721 9.38034 2.43784C10.301 3.35847 10.8182 4.60712 10.8182 5.90909Z'
      stroke='black'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.90907 7.54562C6.81281 7.54562 7.54543 6.81299 7.54543 5.90925C7.54543 5.00551 6.81281 4.27289 5.90907 4.27289C5.00533 4.27289 4.27271 5.00551 4.27271 5.90925C4.27271 6.81299 5.00533 7.54562 5.90907 7.54562Z'
      stroke='black'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
