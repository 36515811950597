import { Service } from 'src/services/base/Service'

interface GetStreakRequest {
  method: typeof UserService.methods.GetStreak
  params: { userId: string }
}

type UserServiceRequest = GetStreakRequest

export class UserService extends Service {
  public static methods = {
    GetStreak: 'getStreak',
  } as const

  public static getSWRKey(request: UserServiceRequest) {
    const { method, params } = request
    switch (method) {
      case UserService.methods.GetStreak:
        return this.buildRequestUrl(`/api/v3/users/${params.userId}/streak`)
      default:
        throw new Error('Method not implemented')
    }
  }

  public static async getStreak(params: GetStreakRequest['params']) {
    return await this.get<number>(this.getSWRKey({ method: UserService.methods.GetStreak, params }))
  }
}
