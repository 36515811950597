export const Checkmark = ({ className }) => (
  <svg width='12' height='11' viewBox='0 0 12 11' fill='none' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.9784 0.442511C11.3066 0.727828 11.3424 1.2264 11.0584 1.5561L3.80568 9.97714C3.65648 10.1504 3.43974 10.2499 3.21174 10.25C2.98374 10.2501 2.76695 10.1506 2.61767 9.97744L0.441848 7.45365C0.157728 7.12409 0.19329 6.6255 0.521279 6.34002C0.849268 6.05454 1.34548 6.09027 1.6296 6.41983L3.21125 8.25442L9.87014 0.522873C10.1541 0.193173 10.6503 0.157194 10.9784 0.442511Z'
      fill='#979797'
    />
  </svg>
)
