import { button, mixin } from 'src/styles'
import { Checkmark } from 'src/components/Display/Checkmark'
import { UnfriendModal } from './UnfriendModal'
import { UserAction } from 'src/components/UserAction'

export const FriendsButtonAndModal = ({ refreshFunction, styles }) => {
  const [showUnfriendButton, setShowUnfriendButton] = React.useState(false)
  const [showUnfriendModal, setShowUnfriendModal] = React.useState(false)
  const Styles = { ...DefaultStyles, ...styles }

  return (
    <Styles.ButtonsContainer>
      <Styles.Button isMuted onClick={() => setShowUnfriendButton(!showUnfriendButton)}>
        <Styles.FriendsTag>
          <Styles.Checkmark />
          Friends
        </Styles.FriendsTag>
      </Styles.Button>
      <Styles.UnfriendButtonContainer isDisplayed={showUnfriendButton}>
        <Styles.Button
          onClick={() => {
            setShowUnfriendButton(false)
            setShowUnfriendModal(true)
          }}
        >
          Unfriend
        </Styles.Button>
      </Styles.UnfriendButtonContainer>
      <UnfriendModal
        isOpen={showUnfriendModal}
        setIsOpen={setShowUnfriendModal}
        refreshFunction={refreshFunction}
      />
    </Styles.ButtonsContainer>
  )
}

const DefaultStyles = {
  ButtonsContainer: styled.div`
    position: relative;
  `,
  Button: styled(UserAction)`
    ${({ isMuted }) =>
      isMuted
        ? css`
            ${button.muted}
          `
        : css`
            ${button.secondary}
          `}

    padding: 4px 16px;
  `,
  FriendsTag: styled.div`
    ${mixin.flexCenterHorizontal}
    align-items: baseline;
    text-transform: uppercase;
    color: var(--mediumGrey);
    letter-spacing: 0.85px;
    font-weight: 400;
  `,
  Checkmark: styled(Checkmark)`
    width: 12px;
    margin-right: 3px;
  `,
  UnfriendButtonContainer: styled.div`
    ${mixin.cardBoxShadow}
    display: none;
    padding: 9px;
    background-color: #fff;
    flex-direction: column;
    position: absolute;
    top: 40px;
    right: -16px;
    width: 127%;

    ${({ isDisplayed }) =>
      isDisplayed &&
      css`
        ${mixin.flexCenterHorizontal}
      `}
  `,
}
