import { usePathname } from 'next/navigation'
import { ReactNode, useEffect, useState } from 'react'
import useSWR from 'swr'

import { Anchor } from 'src/components/Anchor'
import { ImpressionTracker } from 'src/components/App/ImpressionTracker'
import { FullContainer } from 'src/components/Display/Container'
import { Home } from 'src/components/Display/Home'
import { Icon } from 'src/components/Icon'
import { Link } from 'src/components/Link'
import { ProfileCard } from 'src/components/ProfileCard'
import { useMemberContext } from 'src/providers/MemberProvider'
import { breakpoint, mixin } from 'src/styles'
import { NavBar } from 'src/views/Profile/NavBar'
import { Member } from 'src/types/Member'
import { UserService } from 'src/services'

interface Props {
  children: ReactNode
}

export const ProfileLayout = ({ children }: Props) => {
  const pathname = usePathname()
  const { currentMember, isSelf, currentUser } = useMemberContext()
  const [trackingData, setTrackingData] = useState<{
    to?: string
    context?: string
    userId?: string
    profileOwner: 'self' | 'friend'
    profileOwnerId: string | undefined
  }>()

  const isOverviewPage = window?.location?.pathname === `/${currentUser?.profile?.username}`

  const { data: streak } = useSWR(
    () =>
      currentMember?.id && isSelf
        ? UserService.getSWRKey({
            method: UserService.methods.GetStreak,
            params: { userId: currentMember.id },
          })
        : null,
    () => UserService.getStreak({ userId: (currentMember as Member).id }),
    { revalidateOnFocus: false, revalidateOnReconnect: false },
  )

  useEffect(() => {
    if (pathname.includes('collections')) {
      setTrackingData({
        to: pathname,
        context: 'profile_collections',
        profileOwner: isSelf ? 'self' : 'friend',
        profileOwnerId: currentMember?.id,
      })
    } else {
      setTrackingData({
        userId: currentUser?.id,
        profileOwner: isSelf ? 'self' : 'friend',
        profileOwnerId: currentMember?.id,
      })
    }
  }, [currentMember?.id, currentUser?.id, isSelf, pathname])

  return (
    <div>
      <ImpressionTracker
        action={
          pathname.includes('collections')
            ? obe.events.navigation.navigated_to_collection
            : obe.events.navigation.navigated_to_profile
        }
        tracking={{ ...trackingData }}
      >
        <Styles.FullContainer>
          <ProfileCard />
        </Styles.FullContainer>
      </ImpressionTracker>
      <Anchor hash='profile-navbar' />
      <NavBar />
      <Styles.ClassCountContainer hasMarginBottom={!isSelf || !isOverviewPage}>
        <div style={{ display: 'flex' }}>
          <h3>Total Classes Taken: {currentMember?.membershipActivity?.classesCompleted || 0}</h3>
          <Styles.HomeIconContainer>
            <Styles.Home />
            <Styles.Home isBehind />
          </Styles.HomeIconContainer>
        </div>
        {isSelf && !!streak && (
          <ImpressionTracker
            action={obe.events.user_viewed_streak}
            tracking={{
              userId: currentMember?.id,
              streak,
              userStatus: currentMember?.subscription?.status,
            }}
          >
            <Styles.StreakHeader>
              Streak: {streak} Day{streak !== 1 && 's'}
            </Styles.StreakHeader>
          </ImpressionTracker>
        )}
      </Styles.ClassCountContainer>
      {isSelf && isOverviewPage && (
        <Styles.GuestPassBanner>
          Send a guest pass: Share 1 free month of obé with a friend!
          <Styles.GuestPassLink
            action={obe.events.navigation.navigated_to_guest_pass}
            to='/guest-pass'
            tracking={{ context: 'profile' }}
          >
            <Styles.GuestPassIcon /> SEND GUEST PASS
          </Styles.GuestPassLink>
        </Styles.GuestPassBanner>
      )}
      {children}
    </div>
  )
}

const Styles = {
  FullContainer: styled(FullContainer)`
    display: flex;
    flex-direction: column;
  `,
  ClassCountContainer: styled.div<{ hasMarginBottom: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--lightGrey);
    min-height: 80px;
    padding: 16px;

    h3 {
      line-height: 28px;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0.5px;
      margin-bottom: 0px;
    }

    ${({ hasMarginBottom }) =>
      hasMarginBottom &&
      css`
        margin-bottom: 32px;

        ${breakpoint.mediumAndDown} {
          margin-bottom: 20px;
        }
      `}

    ${breakpoint.mediumAndDown} {
      min-height: 60px;
    }
  `,
  HomeIconContainer: styled.div`
    position: relative;
    margin-left: 10px;
    width: 14px;
    height: 16px;
  `,
  Home: styled(Home)<{ isBehind?: boolean }>`
    position: absolute;
    top: 6px;
    left: 0;
    width: 14px;
    height: 16px;
    transform: scale(1.2);
    z-index: 1;

    ${({ isBehind }) =>
      isBehind &&
      css`
        top: 7px;
        right: -2px;
        z-index: 0;
        fill: var(--yellow);

        path {
          stroke: none;
        }
      `}
  `,
  StreakHeader: styled.h3`
    margin-top: 5px;
    font-size: 18px !important;
  `,
  GuestPassBanner: styled.div`
    ${mixin.flexCenterBoth}
    width: 100%;
    background-color: var(--primary400);
    color: var(--white);
    margin-bottom: 32px;
    padding: 22px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    font-size: 18px;
    line-height: 1;
    text-align: center;

    span {
      display: contents;
      font-weight: 500;
    }

    ${breakpoint.mediumAndDown} {
      flex-direction: column;
      margin-bottom: 20px;
      font-size: 14px;
      padding: 14px;
    }
  `,
  GuestPassLink: styled(Link)`
    ${mixin.flexCenterBoth}
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 13px;
    letter-spacing: 0.015em;
    margin-left: 8px;
    white-space: nowrap;
    color: var(--white);

    :hover {
      color: var(--white);
      font-weight: 900;
    }

    ${breakpoint.mediumAndDown} {
      margin-top: 6px;
    }
  `,
  GuestPassIcon: styled(Icon.OpenEnvelope)`
    width: 15px;
    height: 15px;
    margin-right: 2px;
  `,
}
