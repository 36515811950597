export const useInterval = (callback = Function.prototype, delay = 10000) => {
  const lastCallback = React.useRef()
  const intervalId = React.useRef()

  React.useEffect(() => {
    lastCallback.current = callback
  }, [callback])

  React.useEffect(() => {
    const onInterval = () => {
      lastCallback.current()
    }

    clearInterval(intervalId.current)

    intervalId.current = setInterval(onInterval, delay)

    return () => clearInterval(intervalId.current)
  }, [delay])

  return clearInterval.bind(null, intervalId.current)
}
