export const Username = ({ username = '', className }) => (
  <Styles.Username className={className}>
    <Styles.AtSymbol>@</Styles.AtSymbol> {username}
  </Styles.Username>
)

const Styles = {
  Username: styled.div`
    display: flex;
    color: var(--mediumGrey);
    text-align: left;
    font-size: 14px;
  `,
  AtSymbol: styled.div`
    padding-right: 0.04rem;
  `,
}
