'use client'

import { Redirect } from 'src/components/App/Redirect'
import { useUser } from 'src/hooks/useUser'
import { storage } from 'src/storage'

export const IsMember = ({ children, returnUrl = '' }) => {
  const { isLoading, isGuest, isMember } = useUser()

  if (returnUrl) {
    storage.session.setItem('returnUrl', returnUrl)
  }

  if (isMember) {
    storage.session.removeItem('freemiumPartnershipSlug')
    storage.session.removeItem('freemiumAuthCode')
  }

  if (isLoading) {
    return null
  }

  if (isGuest) {
    return <Redirect to='/login' />
  }

  if (!isMember) {
    return <Redirect to='/' />
  }

  return children
}

export const RequireUser = {
  IsMember,
}
