import { useUiStore } from './useStores'

export const useComponentContext = () => {
  const { modalType } = useUiStore()

  return {
    isFriendSearchModal: modalType?.type === obe.ui_component_type.friend_search_modal,
    isContactsModal: modalType?.type === obe.ui_component_type.contacts_modal,
    isAddFriendModal: modalType?.type === obe.ui_component_type.add_friend_modal,
    isVodAttendeeModal: modalType?.type === obe.ui_component_type.vod_attendee_summary_modal,
  }
}
