import CircleType from 'circletype'
import { getImageUrl } from 'src/filestack'
import { Icon } from '../Icon'
import { Image } from 'src/components/Display/Image'
import { mixin } from 'src/styles'
import { userInitials } from 'src/utils/userUtils'
import { EmptyAvatar } from 'src/components/Icon/EmptyAvatar'

export const Avatar = ({
  avatarUrl,
  firstName,
  foundingMember,
  isLevelUp = false,
  isMiniAvatar = false,
  isMediumAvatar = false,
  lastName,
  styles
}) => {
  const Styles = { ...DefaultStyles, ...styles }

  React.useEffect(() => {
    if (foundingMember && !isMiniAvatar && document.getElementById('curve-text')) {
      new CircleType(document.getElementById('curve-text'))
        .dir(-1)
        .radius(66)
        .forceHeight(false)
        .forceWidth(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (avatarUrl) {
    return (
      <Styles.AvatarContainer isMediumAvatar={isMediumAvatar} isMiniAvatar={isMiniAvatar}>
        <Image
          src={getImageUrl(avatarUrl, 'resize=width:256,height:256,fit:crop')}
          circle
          shadow
          alt={`${firstName} ${lastName}`}
        />
        {foundingMember && !isMiniAvatar && (
          <Styles.CurvedText id='curve-text'>Founding Member</Styles.CurvedText>
        )}
        {isLevelUp && !isMiniAvatar && <Styles.LevelUpFlag />}
      </Styles.AvatarContainer>
    )
  }

  const initials = userInitials({ firstName, lastName })

  return (
    <Styles.AvatarContainer isMediumAvatar={isMediumAvatar} isMiniAvatar={isMiniAvatar}>
      {initials ? (
        <Styles.DefaultAvatar isMediumAvatar={isMediumAvatar} isMiniAvatar={isMiniAvatar}>
          <div>{initials}</div>
        </Styles.DefaultAvatar>
      ) : (
        <EmptyAvatar width={128} height={128} />
      )}
      {foundingMember && !isMiniAvatar && !isMediumAvatar && (
        <Styles.CurvedText id='curve-text' isDefaultAvatar>
          Founding Member
        </Styles.CurvedText>
      )}
      {isLevelUp && !isMiniAvatar && !isMediumAvatar && <Styles.LevelUpFlag />}
    </Styles.AvatarContainer>
  )
}

const DefaultStyles = {
  AvatarContainer: styled.div`
    position: relative;
    height: 128px;
    width: 128px;

    ${({ isMediumAvatar }) =>
      isMediumAvatar &&
      css`
        height: 43px;
        width: 43px;
      `}

    ${({ isMiniAvatar }) =>
      isMiniAvatar &&
      css`
        height: 20px;
        width: 20px;
      `}
  `,
  DefaultAvatar: styled.div`
    ${mixin.flexCenterBoth}
    ${mixin.obeGradient}
    border-radius: 50%;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    font-size: 25px;
    line-height: 1;
    height: 100%;

    ${({ isMediumAvatar }) =>
      isMediumAvatar &&
      css`
        font-size: 12px;
        height: 40px;
        width: 40px;
        box-shadow: none;
        background-image: null;
        background-size: null;
        background-repeat: no-repeat;
      `}

    ${({ isMiniAvatar }) =>
      isMiniAvatar &&
      css`
        font-size: 9px;
      `}
  `,
  CurvedText: styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    font-size: 11px;
    color: white;
    font-weight: 600;
    letter-spacing: -0.5px;

    ${({ isDefaultAvatar }) =>
      isDefaultAvatar &&
      css`
        color: var(--nearBlack);
      `}
  `,
  LevelUpFlag: styled(Icon.LevelUpFlag)`
    position: absolute;
    top: 2px;
    right: 10%;
    width: 17%;
    height: fit-content;
  `
}
