import * as Sentry from '@sentry/nextjs'

import { api, client } from 'src/api'
import type { JSONSerializable } from 'src/httpClient'

export class Service {
  protected static session = api.sessionState

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected static getSWRKey(request: never) {
    throw new Error('Method not implemented')
  }

  protected static async get<T>(path: string) {
    const response = await client.get<T>(path)
    return response.data
  }

  protected static async post<T, D extends JSONSerializable>(path: string, data: D) {
    const response = await client.post<T>(path, data)
    return response.data
  }

  protected static async put<T, D extends JSONSerializable>(path: string, data: D) {
    const response = await client.put<T>(path, data)
    return response.data
  }

  protected static async patch<T, D extends JSONSerializable>(path: string, data: D) {
    const response = await client.patch<T>(path, data)
    return response.data
  }

  protected static async delete<T>(path: string) {
    const response = await client.delete<T>(path)
    return response.data
  }

  protected static buildRequestUrl(path: string, params?: Record<string, string>) {
    if (!params) return path
    const searchParams = new URLSearchParams()
    for (const [key, value] of Object.entries(params)) {
      searchParams.append(key, value)
    }
    return `${path}?${searchParams.toString()}`
  }

  protected static logException(...args: Parameters<typeof Sentry.captureException>) {
    Sentry.captureException(args)
  }
}
