import type { SVGProps } from 'react'

export const Home = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg width='18' height='23' viewBox='0 0 18 21' fill='none' className={className} {...props}>
    <path
      d='M9.08511 8.65957V1L1 11.6383H9.08511V20.1489L16.7447 8.65957H9.08511Z'
      stroke='black'
      strokeWidth='1.1'
      strokeLinejoin='round'
    />
  </svg>
)
