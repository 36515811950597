import { breakpoint } from 'src/styles'
import { Dialog } from 'src/components/Dialog'

export const ErrorModal = ({ error, isOpen, refreshFunction, setIsOpen }) => (
  <Styles.Dialog isOpen={isOpen} onClosed={() => (refreshFunction ? refreshFunction() : undefined)}>
    <Dialog.Header noBorder onClick={() => setIsOpen(false)} />
    <Styles.Body>
      <Styles.Error>{error}</Styles.Error>
    </Styles.Body>
  </Styles.Dialog>
)

const Styles = {
  Error: styled.div`
    font-size: 24px;
    text-align: center;
    font-weight: 300;
    padding: 0 16px;

    ${breakpoint.mediumAndDown} {
      font-size: 22px;
    }
  `,
  Body: styled.div`
    padding: 15px;
  `,
  Dialog: styled(Dialog.Main)`
    width: 500px;

    ${breakpoint.mediumAndDown} {
      width: 355px;
    }
  `,
}
