import { client } from 'src/api'
import type { FacetsCarousel, ProgramCarousel } from 'src/types/Carousel'
import type { Program, ProgramCategory, ProgramEnrollment, ProgramWeek } from 'src/types/Program'

export const getProgramCarousels = async () => {
  const { data } = await client.get<(FacetsCarousel | ProgramCarousel)[]>(
    '/api/v4/carousels/programs_collection',
    {
      params: { slug: 'programs-v3' },
    },
  )
  const goals = data?.find((carousel) => carousel?.carouselType === 'facet') as FacetsCarousel
  const programCarousels = data?.filter(
    (carousel) => carousel?.carouselType !== 'facet',
  ) as ProgramCarousel[]
  return {
    goals: goals?.items?.filter((item) => !!item) || [],
    goalsTitle: goals?.title,
    programCarousels,
  }
}

export const getProgramsByGoal = async (slug: string) => {
  const { data } = await client.post<Program[]>('/api/v4/programs', {
    filters: { fitnessGoal: [slug] },
  })
  return data
}

export const getProgramsByCategory = async (slug: string) => {
  const { data } = await client.post<Program[]>('/api/v4/programs', {
    filters: { programCategory: [slug] },
  })
  return data
}

export const getAllChallenges = async () => {
  const response = await client.post<Program[]>('/api/v4/programs', {
    isChallenge: true,
  })

  return response
}

export const getProgramV2BySlug = async (slug = '') => {
  try {
    const { data } = await client.get<Program>('/api/v4/programs/by_slug', { params: { slug } })
    return data
  } catch (error) {
    return null
  }
}

export const getProgramStatus = (id: string) =>
  client.get<ProgramEnrollment>(
    `/api/v3/obe_program_enrollments/user_progress?obe_program_id=${id}`,
  )

export const getProgramEnrollments = async (userId?: string) => {
  const { data } = userId
    ? await client.get<ProgramEnrollment[]>(`/api/v3/obe_program_enrollments?for_member=${userId}`)
    : await client.get<ProgramEnrollment[]>('/api/v3/obe_program_enrollments')
  return data
}

export const startProgram = (id: string) =>
  client.post<ProgramEnrollment>('/api/v3/obe_program_enrollments/start_program', {
    obe_program_id: id,
  })

export const rateProgram = (id: string, rating: number) =>
  client.put<void>('/api/v3/obe_program_enrollments/rate_program', {
    obe_program_id: id,
    rating,
  })

export const stopProgram = (id: string) =>
  client.put<ProgramEnrollment>('/api/v3/obe_program_enrollments/stop_program', {
    obe_program_id: id,
  })

export const getProgramWeeks = (programId: string) =>
  client.get<ProgramWeek[]>(`/api/v4/programs/${programId}/week_details`)

export const getAllProgramSlugs = () =>
  client.get<{ slugs: string[] }>('/api/v4/programs/all_slugs')

export const getObeProgram = async (id: string) => {
  const { data: program } = await client.get<Program>(`/api/v4/programs/${id}`)
  return program
}

export const getObeProgramRelated = async (id: string) => {
  const { data: related } = await client.get<Program[]>(`/api/v4/programs/${id}/related`)
  return related
}

export const getAllProgramCategories = async () => {
  const { data: categories } = await client.get<ProgramCategory[]>('/api/v4/program_categories')

  return categories
}
