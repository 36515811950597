'use client'

import { useParams, usePathname } from 'next/navigation'
import { createContext, useContext, useMemo, type ReactNode } from 'react'
import useSwr, { useSWRConfig } from 'swr'

import { Loader } from 'src/components/Display/Loader'
import { useUser } from 'src/hooks/useUser'
import { getProgramEnrollments } from 'src/models/program'
import { MemberService } from 'src/services'
import type { Member } from 'src/types/Member'
import type { ProgramEnrollment } from 'src/types/Program'
import type { UserType } from 'src/types/User'
import { ProgramEnrollmentStatus } from 'src/constants'

interface MemberState {
  canUseSocialFeatures: boolean
  currentEnrolledProgramIds: string[]
  currentCompletedProgramIds: string[]
  currentMember: Member | UserType | null
  currentProgramData: ProgramEnrollment | null
  currentUser: UserType | null
  isFriend: boolean
  isNonbillable: boolean
  isSelf: boolean
  isStripeUser: boolean
  username: string | undefined
  isLoadingEnrollments: boolean
}

export const MemberContext = createContext<MemberState | null>(null)

interface Props {
  children?:
    | (({ isFriend, isSelf }: { isFriend: boolean; isSelf: boolean }) => ReactNode)
    | ReactNode
}

// Params has a bunch of optional chaining because this Provider is currently used in the `/programs` page
// which doesn't have any params. Please remove this optional chain if we stop using this
// provider inside the `/programs` page.
export const MemberProvider = ({ children }: Props) => {
  const { canUseSocialFeatures, currentUser, isNonbillable, isStripeUser, username } = useUser()
  const params = useParams()
  const pathname = usePathname()

  const { cache } = useSWRConfig()

  const isSelf = useMemo(() => {
    return (
      params?.username === username ||
      pathname?.includes('profile') ||
      (pathname?.includes('programs') && !!username)
    )
  }, [params?.username, username, pathname])

  const { data: currentMember, isValidating: isValidatingMember } = useSwr(
    params?.username
      ? MemberService.getSWRKey({
          method: MemberService.methods.GetMember,
          params: { username: params?.username as string },
        })
      : null,
    () => MemberService.getMember({ username: params?.username as string }),
    {
      revalidateOnMount: isSelf || !cache.get(params?.username as string),
      revalidateOnFocus: false,
    },
  )

  const { data: programEnrollments, isValidating: isLoadingEnrollments } = useSwr(
    currentMember ? currentMember.id : currentUser ? currentUser.id : null,
    (id) => getProgramEnrollments(isSelf ? undefined : id),
    { revalidateOnMount: true, revalidateOnFocus: false },
  )

  const currentProgramData = useMemo(() => {
    return (
      programEnrollments?.find(
        ({ programType, status }) => status === 'active' && programType === 'v2',
      ) ?? null
    )
  }, [programEnrollments])

  const currentEnrolledProgramIds = useMemo(() => {
    return (
      programEnrollments
        ?.filter(
          ({ status }) =>
            status === ProgramEnrollmentStatus.ACTIVE || status === ProgramEnrollmentStatus.PAUSED,
        )
        .map(({ obeProgramId }) => obeProgramId) ?? []
    )
  }, [programEnrollments])

  const currentCompletedProgramIds = useMemo(() => {
    return (
      programEnrollments
        ?.filter(({ status }) => status === ProgramEnrollmentStatus.COMPLETED)
        .map(({ obeProgramId }) => obeProgramId) ?? []
    )
  }, [programEnrollments])

  const isFriend = !isSelf && !!currentMember?.friendDto

  return (
    <MemberContext.Provider
      value={{
        canUseSocialFeatures,
        currentEnrolledProgramIds,
        currentCompletedProgramIds,
        currentMember: currentMember ?? currentUser,
        currentProgramData,
        currentUser,
        isFriend,
        isNonbillable,
        isSelf,
        isStripeUser,
        username: currentMember ? currentMember?.profile?.username : currentUser?.profile.username,
        isLoadingEnrollments,
      }}
    >
      <Loader isLoading={isValidatingMember || isLoadingEnrollments}>
        {typeof children === 'function'
          ? children({
              isFriend,
              isSelf,
            })
          : children}
      </Loader>
    </MemberContext.Provider>
  )
}

export const useMemberContext = () => {
  const context = useContext(MemberContext)
  if (!context) {
    throw new Error('useMemberContext must be used within a MemberProvider')
  }
  return context
}
